import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "./NavLink";
import { Flex } from "../Flex/Flex";
import style from "./style.module.scss";
import { Input } from "@mui/icons-material";
import { ToggleContactModal } from "../../Redux/Actions";
import { AppState } from "../../Redux/Models";
import { Logo } from "../Logo";
import { useHistory } from "react-router-dom";
import { CheckList, Pilot, Business, Indivudual, Analytics, Roadmap, Team, Vision, Chart, Shield, Product, Planning, Timewheel, Assessment, Resources, HandShakeImg, BusinessStructureImg, LockImg, LineGraphImg } from "../../Assets/Icons";
import { Hamburger } from "../";
import ReactGa from 'react-ga';
import { GetVariant } from "../../Utils/Variant";
import { WindowSizes } from "../../Redux/Models/Utility/WIndowSizes";
import { useAppHeaderTheme } from "./useAppHeaderTheme";
const smallLogoWindowSizes = new Set([
  WindowSizes.smallTablet,
  WindowSizes.tablet,
  WindowSizes.mobile,
  WindowSizes.tinyMobile,
  WindowSizes.smallMobile
])

export const MC2NavItem = () => (
  <span>
  Mc<sup style={{ fontSize: 16 }}>2</sup><sub style={{fontWeight: 100, fontSize: 14}}>&trade;</sub> Process
  </span>
)

export const Header = React.memo((props: any) => {

  const { loading: bLoading, data: bData, error: bError } = useSelector((s: AppState) => s.content.businessServices)
  const { loading: iLoading, data: iData, error: iError } = useSelector((s: AppState) => s.content.individualServices)
  const { showUpcoming } = GetVariant();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLarge, setIsLarge] = useState(true);
  const [selectedRoute, setSelectedRoute] = useState<any>(null);
  const appWindowSize = useSelector((s: AppState) => s.utility.appWindowSize);
  const [appHeaderTheme] = useAppHeaderTheme()
  const [headerTheme] = useAppHeaderTheme()
  const [isScrolling, setIsScrolling] = useState(false);

  const onScroll = useCallback((event: any) => {
    setIsScrolling(window.scrollY > 25);
  }, []);


  useEffect(() => {
    setIsScrolling(window.scrollY > 25);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  }, [onScroll]);

  const isSmallScreen = useMemo(() => smallLogoWindowSizes.has(appWindowSize), [appWindowSize])

  useEffect(() => {
    ReactGa.pageview(history.location.pathname);
    setSelectedRoute(history.location.pathname)
    //@ts-ignore
    const unlisten = history.listen((location: any, action: any) => {
      // If there's a hash in the url then don't manage the scroll
      if (!location.hash) {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }
      ReactGa.pageview(location.pathname);
      setSelectedRoute(location.pathname)
    });
    return () => { unlisten() }
  }, [history])


  const navGroups = useMemo(() => ({
    businessSolutions: [
      // ...(bData?.map(d => ({ text: d.Service?.Title || '', to: `/services#${d.ContentId}` })) || []),
      { text: 'Individuals', to: '/services#individual-service-section-1', img: Indivudual },
      { text: 'Business Owners', to: '/business-solutions', img: Business },
      { text: "Aviation Professionals", to: '/pilots#hero', img: Pilot }
    ],
    services: [
      // ...(iData?.map(d => ({ text: d.Service?.Title || '', to: `/services#${d.ContentId}` })) || []),
      { text: "Financial Planning", to: '/services#individual-service-section-4', img: Analytics },
      { text: "Retirement Planning", to: '/services#business-service-section-2', img: Roadmap },
      { text: 'Wealth Planning', to: '/services#individual-service-section-1', img: Chart },
      { text: 'Tax Planning', to: '/services#individual-service-section-2', img: CheckList },
      { text: 'Estate Planning', to: '/services#individual-service-section-5', img: Product },
      { text: 'Planning for Business Owners', to: '/services#business-service-section-1', img: Planning },
    ],
    about: [
      { text: "Our Vision", to: '/about#our-vision', img: Vision },
      { text: "Our Principles", to: '/about#our-principles', img: Shield },
      { text: "Our Team", to: '/about#team-bio', img: Team },
    ],
    emc2: [
      { text: "Our Philosphy", to: '/emc2#mc2-process', img: Timewheel },
      { text: "Our Mission", to: '/emc2#ecm2-protect', img: HandShakeImg },
      { text: "Our Principles", to: '/emc2#plan-protect-prosper', img: BusinessStructureImg },
    ],
    upcoming: [
      { text: "Events", to: '/events#upcoming', img: Timewheel },
      { text: "Portfolio", to: '/portfolio#insights', img: Analytics },
    ],
    tools: [
      { text: "EHA Portal", to: 'https://wealth.emaplan.com/ema/signin', openVariant: 'tab', img: Timewheel },
      { text: "Wealthscape", to: 'https://www.mystreetscape.com/login/access/investor-index.html', openVariant: 'tab', img: Shield },
      { text: "Risk Profiler", to: '/risk-profiler', img: Assessment },
      { text: "Resources", to: '/resources', img: Resources },
    ],
    // }), [iData, bData])
  }), [])

  const isAboutSelected = useMemo(() => selectedRoute === '/about', [selectedRoute])
  const isEmc2Selected = useMemo(() => selectedRoute === '/emc2', [selectedRoute])
  const isServicesSelected = useMemo(() => selectedRoute === '/services', [selectedRoute])
  const isBusinessSolutionsSelected = useMemo(() => navGroups.businessSolutions.some(r => r.to === selectedRoute), [selectedRoute])
  const isUpcomingSelected = useMemo(() => navGroups.upcoming.some(r => r.to === selectedRoute), [selectedRoute])

  const handleSignIn = useCallback(() => window.open('https://wealth.emaplan.com/ema/signin', '_blank'), [])

  const backgroundStyles = useMemo(() => {
    switch (headerTheme) {
      case 'transparent-start': return isScrolling ? style.wrapperPrimary : style.wrapperTransparentLight
      case 'primary': default: return style.wrapperPrimary
    }
  }, [headerTheme, isScrolling])

  const fontColor = useMemo(() => {
    switch (headerTheme) {
      case 'transparent-start': return isScrolling ? 'black' : 'white'
      case 'primary': default: return 'black'
    }
  }, [headerTheme, isScrolling])

  const buttonTheme = useMemo(() => {
    switch (headerTheme) {
      case 'transparent-start': return isScrolling ? 'callToActionBlack' : 'callToActionWhite'
      case 'primary': default: return 'callToActionBlack'
    }
  }, [headerTheme, isScrolling])


  if (!bData?.length || !iData?.length) return null;

  return (
    <Flex id="app-header" row className={`${style.wrapper} ${backgroundStyles}`} align="center" justify="space-between">
      <Logo isScrolling={isScrolling} size={isSmallScreen ? 70 : 170} onSizeChange={setIsLarge} withoutSubText={!isLarge} />
      <Flex justify="flex-end" style={{ alignSelf: isLarge ? 'flex-start' : 'center', flex: 1 }}>
        <Flex className={style.navSection} justify="flex-end" align="center" style={{
          transition: '200ms',
          marginTop: 10, alignSelf: isLarge ? 'inherit' : 'center',
          color: fontColor
        }}>
          <NavLink subItems={navGroups.about} route="/about" text={"Who we are"} selected={isAboutSelected} />
          <NavLink style={{ marginBottom: 6}} subItems={navGroups.emc2} route="/emc2" text={<MC2NavItem/>} selected={isEmc2Selected} />
          <NavLink subItems={navGroups.services} route="/services" text={"What we do"} selected={isServicesSelected} />
          <NavLink route="/business-solutions" subItems={navGroups.businessSolutions} text={"Who we serve"} selected={isBusinessSolutionsSelected} />
          {showUpcoming && <NavLink subItems={navGroups.upcoming} route="/business-solutions" text={"Upcoming"} selected={isUpcomingSelected} />}
          <NavLink
            isButton
            subItems={navGroups.tools}
            text={"Client portal"}
            buttonIcon={Input}
            onClick={handleSignIn}
            buttonVariant={buttonTheme}
            route={''}
          />
        </Flex>
      </Flex>
      <Hamburger />
    </Flex >
  );
});
