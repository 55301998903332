import React from "react";
import style from "./style.module.scss";
import { Flex } from "../../../Components";
import { ContentSection } from "../../../Components/ContentSection";
import { CoupleConsultImg, ProsperTreeImg, Emc2PieImg } from "../../../Assets";

const Ecm2ProcessSectionHeader = () => {
  const Ecm2DescriptionBlock = () => (
    <Flex
      style={{
        boxShadow: "10px 15px 100px -10px rgba(0,0,0,0.4)",
        borderRadius: 10,
        background: "#CBE3ED",
        maxWidth: 600,
      }}
    >
      <div className={style.ecm2ProcessHighlight}>
        <span className={style.ecm2ProcessText}>
          Our process is designed to understand what <strong>M</strong>atters
          most to our clients, <strong>C</strong>ontrol Risk and build{" "}
          <strong>C</strong>ertainty in todays uncertain world
        </span>
      </div>
    </Flex>
  );

  return (
    <>
      <h2 style={{ marginTop: 10, paddingTop: 0 }}>
        The Mc<sup style={{ fontWeight: "bold", fontSize: 24 }}>2</sup>
        <sub style={{ fontWeight: 100, fontSize: 18 }}>&trade;</sub> Process
      </h2>
      <Ecm2DescriptionBlock />
    </>
  );
};

const Ecm2ContentSection = () => (
  <>
    <p style={{ fontSize: 22, fontWeight: 500, lineHeight: 1.5 }}>
      Utilizing a uniquely comprehensive process we call{" "}
      <strong style={{ color: "#0177CD" }}>
        Mc<sup style={{ fontSize: 16 }}>2</sup>
        <sub style={{ fontWeight: 100, fontSize: 14 }}>&trade;</sub>
      </strong>
      , we work closely with a select group of highly-accomplished individuals,
      professionals and business owners to help you achieve complete financial
      independence.
    </p>
  </>
);

const ecm2SectionData: any[] = [
  {
    reverse: true,
    type: "white",
    headerVariant: "jsx",
    header: <Ecm2ProcessSectionHeader />,
    id: "mc2-process",
    imageBackgroundColor: "transparent",
    foregroundBackgroundColor: "transparent",
    dropShadowColor: "transparent",
    contentVariant: "jsx",
    content: <Ecm2ContentSection />,
    src: Emc2PieImg,
  },
  {
    reverse: false,
    header: "Protect Your Future",
    type: "white",
    id: "ecm2-protect",
    content:
      "At Eagle Harbor, our only mission is to help you meet and surpass all your financial needs and goals. Our elite team of highly-qualified financial and investment specialists have the resources required to help you get there as efficiently as possible, while protecting, building and managing the wealth you’ve worked so hard to achieve. ",
    src: CoupleConsultImg,
  },

  {
    reverse: true,
    header: "Plan. Protect. Prosper.",
    type: "wave",
    id: "plan-protect-prosper",
    fill: "#2993D1",
    dropShadowColor: "rgba(55, 167, 219, 0)",
    imageBackgroundColor: "transparent",
    foregroundBackgroundColor: "transparent",
    content:
      "Capital preservation and the principles of effective compounding. Utilizing sound, unbiased, enhanced strategies built on a foundation of downside protection. Our proprietary models utilize both strategic and tactical allocations to minimize risk and maximize returns for each client’s unique needs.",
    extraContentBackground: "rgb(32,30,80)",
    src: ProsperTreeImg,
  },
];

export const EMC2Section = () => {
  return (
    <Flex column className={style.wrapper}>
      {ecm2SectionData.map((section, index) => (
        <ContentSection key={index} {...section} />
      ))}
    </Flex>
  );
};
