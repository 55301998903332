import React from 'react';
import { Flex } from "../../Flex";
import style from './style.module.scss';
import { MoreButton } from '../../MoreButton';

const Particle = React.memo(() => {
    return (
        <Flex style={{ position: 'relative' }}>
            <div className={style.particle}></div>
            <div className={style.particle}></div>
            <div className={style.particle}></div>
        </Flex>
    )
});

export interface StyledDetailProps {
    type: 'name' | 'title' | 'phone' | 'contact' | 'linkedin' | 'description' | any;
    child?: any;
    hovered?: boolean;
    href?: string;
}

export const StyledDetail = React.memo((props: StyledDetailProps) => {
    const { type, child, href, hovered = false } = props;

    switch (type) {
        case 'name':
            return <h2 style={{ display: 'flex' }} className={style.name}>{child?.split(" ").map((s: any) => (
                <>
                    {s}
                    <br />
                </>
            ))}</h2>
        case 'title': return <span className={style.title}>{child}</span>
        case 'phone': return <span className={style.phone}>{child}</span>
        case 'contact': return <MoreButton hovered={hovered}>{`Contact ${child.split(' ')[0]}`}</MoreButton>
        case 'linkedin':
            return <span className={style.linkedin}>
                <a href={href} rel="noreferrer" target="_blank">LinkedIn</a>
            </span>
        case 'description': return <p className={style.description}>{child}</p>
        default:
            return <span>{child}</span>
    }
});

export interface ProfileDetailsProps {
    data: any;
}

export const ProfileDetails = (props: ProfileDetailsProps) => {
    const { data } = props;
    return (
        <Flex className={style.container} column>
            <Flex className={style.contact_info_wrapper}>
                <Flex
                    className={style.wrapper}
                    style={{ flex: 1 }}
                    column
                    justify="flex-start"
                >
                    <StyledDetail type='name' child={data.FullName} />
                    <StyledDetail type='title' child={data.Designations} />
                    <StyledDetail type='title' child={data.Hierarchy} />
                    <StyledDetail type='title' child={data.Title} />
                    <StyledDetail type='title' child={data.Email} />
                    {data?.Cell && <StyledDetail type='phone' child={`(C) ${data.Cell}`} />}
                    <StyledDetail type='phone' child={`(O) ${data.Office}`} />
                    {/* <StyledDetail type='contact' child={`${data.name.split(' ')[0]}`} /> */}

                </Flex>
                <Flex className={style.imageWrapper} column justify="flex-end">
                    <img
                        className={style.image}
                        draggable="false"
                        src={data?.Image?.url}
                        alt="bio" />
                    <Particle />
                </Flex>
            </Flex>
            <Flex className={style.bio_details}>
                <StyledDetail type='description' child={data.Bio} />
            </Flex>
        </Flex >
    )
};