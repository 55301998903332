import React, { useState, memo, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonTheme, Flex } from "../..";
import style from "./style.module.scss";
import { HashLink as Link } from 'react-router-hash-link';

export const NavLink = memo((props: {
  text: any;
  subItems?: { text: string, to: string, img?: string }[];
  route: string;
  alternate?: boolean;
  style?: any;
  selected?: boolean;
  img?: string;
  isButton?: boolean;
  buttonVariant?: ButtonTheme
  buttonIcon?: any;
  onClick?: () => void;
}) => {
  const history = useHistory();
  const [hovered, setHovered] = useState(false);

  const handleHoveredTrue = useCallback(() => setHovered(true), [])
  const handleHoveredFalse = useCallback(() => setHovered(false), [])

  const handleNavigate = useCallback(() => history.push(props.route), [props.route])

  return (
    <Flex column style={{ position: "relative" }} onMouseEnter={handleHoveredTrue} onMouseLeave={handleHoveredFalse}>
      <Flex
        column
        align="center"
        onMouseEnter={handleHoveredTrue}
        className={`${style.wrapper} ${!props.alternate && !props.isButton ? style.primary : style.alternate
          } ${props.selected ? style.primaryActive : ''}`}
        style={{ ...props.style }}
      >
        {!props.isButton && <span onClick={handleNavigate}>{props.text}</span>}
        {props.isButton &&
          <Button
            IconComponent={props.buttonIcon}
            onClick={props.onClick}
            theme={props.buttonVariant || "callToActionBlack"} 
            containerStyle={{ marginLeft: 20, padding: "0 10px" }}
          >
            {props.text}
          </Button>
        }
        {hovered && props.subItems?.length &&
          <Flex className={`${props.isButton && style.isButton}`} column onMouseEnter={handleHoveredTrue} style={{ padding: 10, borderRadius: 10, background: "white", position: 'absolute', top: '42px', boxShadow: '0px 15px 30px -5px rgba(0,0,0,0.5)' }}>
            {props.subItems.map((item, idx) => <SubItemLink key={idx} {...item} />)}
          </Flex>}
      </Flex>
    </Flex>
  );
});

interface SubItemLinkProps {
  text: string;
  to: string;
  img?: string | undefined;
  openVariant?: 'tab' | 'internal'
}

const NavLinkImage = React.memo(({ img }: { img?: string }) => {
  return (
    <>
      <img alt="link reference" draggable="false" src={img} style={{ alignSelf: 'center', marginRight: '12px' }} />
    </>
  )
});

const SubItemLink = React.memo((props: SubItemLinkProps) => {
  const { to, text, img, openVariant = 'internal' } = props;

  const handleOpenTab = useCallback(() => window.open(to, '_blank'), [])

  if (openVariant === 'tab') return (
    <div onClick={handleOpenTab}>
      <Flex className={`${style.wrapper} ${style.subLink}`}>
        <span>
          <NavLinkImage img={img} />
          {text}
        </span>
      </Flex>
    </div>
  )

  return (
    <Link smooth to={to}>
      <Flex className={`${style.wrapper} ${style.subLink}`}>
        <span>
          <NavLinkImage img={img} />
          {text}
        </span>
      </Flex>
    </Link>
  )
})