import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  VolumeOffRounded,
  VolumeMuteRounded,
  ReplayRounded,
} from "@mui/icons-material";
import { motion, useScroll, useTransform } from "framer-motion";
import ReactPlayer from "react-player";
import style from "./style.module.scss";

interface AnimatedVideoProps {
  videoUrl: string;
}

const YouTubeConfig = {
  youtube: {
    playerVars: {
      modestbranding: 1,
      controls: 0,
      disablekb: 1,
      fs: 0,
      rel: 0,
      showinfo: 0,
    },
  },
};

export const AnimatedVideo = ({ videoUrl }: AnimatedVideoProps) => {
  const containerRef = useRef(null);
  const videoRef: React.LegacyRef<ReactPlayer> = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [hasUnmutedBefore, setHasUnmutedBefore] = useState(false);
  const [muted, setMuted] = useState(true);

  const soundIcon = useMemo(() => {
    return muted ? (
      <VolumeMuteRounded className={style.videoControlIcon} />
    ) : (
      <VolumeOffRounded className={style.videoControlIcon} />
    );
  }, [muted]);

  const handleReplay = useCallback(
    () => videoRef?.current?.seekTo(0),
    [videoRef]
  );

  const handleSoundToggle = useCallback(() => {
    setMuted(!muted);
    if (!hasUnmutedBefore) {
      setHasUnmutedBefore(true);
      handleReplay();
    }
  }, [muted, setMuted, handleReplay, hasUnmutedBefore]);

  const handleLoaded = useCallback(() => setLoaded(true), []);

  const videoSection = useMemo(
    () => (
      <div className={style.videoSection}>
        <ReactPlayer
          ref={videoRef}
          style={{ visibility: loaded ? "visible" : "hidden" }}
          className={style.video}
          width="100%"
          onStart={handleLoaded}
          height="100%"
          loop={true}
          muted={muted}
          playsinline={true}
          controls={false}
          stopOnUnmount={false}
          url={videoUrl}
          playing={true}
          config={YouTubeConfig}
        />
        <div className={style.videoOverlay}>
          <div className={style.videoPlayerControls}>
            <div onClick={handleReplay} className={style.videoControlButton}>
              <ReplayRounded className={style.videoControlIcon} />
            </div>
            <div
              onClick={handleSoundToggle}
              className={style.videoControlButton}
            >
              {soundIcon}
            </div>
          </div>
        </div>
      </div>
    ),
    [handleSoundToggle, loaded, muted, soundIcon]
  );

  const { scrollYProgress } = useScroll({
    target: containerRef,
    // offset: ["end end", "start end"]
    offset: ["start end", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [0.6, 0.5]);

  const y = useTransform(scrollYProgress, [0, 1], ["-20%", "0%"]);

  const animatedVideo = useMemo(() => {
    return (
      <div ref={containerRef} className={style.container}>
        <motion.div
          style={{
            scale,
            y,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {videoSection}
        </motion.div>
      </div>
    );
  }, [videoSection]);

  return <>{animatedVideo}</>;
};
