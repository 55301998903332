import React, { useCallback, useEffect, useState, useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import InputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Header, Footer, SEO } from "./Components";
import { AppointmentModal } from "./Components/Modals/AppointmentModal";
import { SuccessAlert } from "./Components/Alerts";
import { ContactModal } from "./Components/Modals/ContactModal";
import {
  Home,
  About,
  Services,
  NotFound,
  Media,
  Portfolio,
  Events,
  BusinessSolutions,
  Pilots,
  RiskProfiler,
  Calendly,
} from "./Pages";
import { ParallaxProvider } from "react-scroll-parallax";
import { StaticAppointmentButton } from "./Components/Button/StaticAppointmentButton";
import { SocialMediaBlock } from "./Components/SocialMediaBlock";
import RetirementPlanning from "./Assets/Images/retirementPlanning.jpeg";
import EstatePlanning from "./Assets/Images/estatePlanning.png";
import {
  FinancialPlan,
  TeamImage,
  Office,
  Consultation,
  WaypointMonitoring,
  EagleParallax,
  Plane,
  TeamImagev2,
  Venue,
  PilotsCockpit,
  BusinessOffice,
  MedicalProfessional,
  RetirementSponsor,
} from "./Assets";
import {
  ExitStrategy,
  RiskIcon,
  SettingsChartIcon,
  ChartUpIcon,
  BarChart,
  Plan,
  MitigatingRisk,
  PlanningPilots,
  StockOptions,
  CheckList,
  Pilot,
  Business,
  Indivudual,
  Analytics,
  Roadmap,
  Team,
  Vision,
  Chart,
  Shield,
  Product,
  Timewheel,
  HandShakeImg,
  BusinessStructureImg,
} from "./Assets/Icons";
import { LoadingSpinner } from "./Components/LottieFIles/LoadingSpinner";
import { UPDATE_WINDOW_SIZE } from "./Redux/Constants";
import { WindowSizes } from "./Redux/Models/Utility/WIndowSizes";
import {
  GetBusinessServicesAction,
  GetIndividualServicesAction,
  GetMainMetaTagsAction,
} from "./Redux/Actions";
import { MobileDrawer } from "./Components";
import ReactGa from "react-ga";
import { GetVariant } from "./Utils/Variant";
import { TradingViewMarque } from "./Components/TradingViewWidgets";
import { useLocalStorage } from "./Hooks";
import style from "./App.module.scss";
import { Pin } from "./Constants/pin";
import "./App.scss";
import { Emc2 } from "./Pages/EMC2";

const asyncImageLoaders = [
  EagleParallax,
  Plane,
  RetirementPlanning,
  EstatePlanning,
  FinancialPlan,
  ExitStrategy,
  TeamImage,
  TeamImagev2,
  Office,
  RiskIcon,
  SettingsChartIcon,
  ChartUpIcon,
  BarChart,
  Plan,
  MitigatingRisk,
  PlanningPilots,
  StockOptions,
  Consultation,
  WaypointMonitoring,
  CheckList,
  Pilot,
  Business,
  Indivudual,
  Analytics,
  Roadmap,
  Team,
  Vision,
  Chart,
  Shield,
  Product,
  Venue,
  PilotsCockpit,
  BusinessOffice,
  MedicalProfessional,
  RetirementSponsor,
  Timewheel,
  HandShakeImg,
  BusinessStructureImg,
].map(async (src) => {
  let img;
  return new Promise((resolve, reject) => {
    img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = src;
  });
});

const smallLogoWindowSizes = new Set([
  WindowSizes.smallTablet,
  WindowSizes.tablet,
  WindowSizes.mobile,
  WindowSizes.tinyMobile,
  WindowSizes.smallMobile,
]);

function App(props) {
  const { trackAnalytics, showLockScreen } = GetVariant();
  const [pinVerified] = useLocalStorage("PIN_VERIFIED", false);
  const dispatch = useDispatch();
  const appSize = useSelector((s) => s.utility.appWindowSize);
  const data = useSelector((s) => s.content.metaDataTags.data);

  useEffect(() => {
    if (trackAnalytics) ReactGa.initialize("UA-210362913-1");
  }, [trackAnalytics]);

  const updateWindowSize = useCallback(
    (size) => dispatch({ type: UPDATE_WINDOW_SIZE, size }),
    []
  );

  const resizeListener = useCallback(() => {
    if (window.innerWidth <= 450 && appSize !== WindowSizes.tinyMobile)
      updateWindowSize(WindowSizes.tinyMobile);
    else if (window.innerWidth <= 650 && appSize !== WindowSizes.smallMobile)
      updateWindowSize(WindowSizes.smallMobile);
    else if (window.innerWidth <= 875 && appSize !== WindowSizes.mobile)
      updateWindowSize(WindowSizes.mobile);
    else if (window.innerWidth <= 1000 && appSize !== WindowSizes.smallTablet)
      updateWindowSize(WindowSizes.smallTablet);
    else if (window.innerWidth <= 1200 && appSize !== WindowSizes.tablet)
      updateWindowSize(WindowSizes.tablet);
    else if (window.innerWidth <= 1399 && appSize !== WindowSizes.small)
      updateWindowSize(WindowSizes.small);
    else if (window.innerWidth <= 1550 && appSize !== WindowSizes.regular)
      updateWindowSize(WindowSizes.regular);
    else if (window.innerWidth > 1550 && appSize !== WindowSizes.large)
      updateWindowSize(WindowSizes.large);
    else if (window.innerWidth > 2000 && appSize !== WindowSizes.extraLarge)
      updateWindowSize(WindowSizes.extraLarge);
  }, [updateWindowSize]);

  /* Commented out bc it may be the cause of some performance issues */
  useEffect(() => {
    dispatch(GetBusinessServicesAction());
    dispatch(GetIndividualServicesAction());
    dispatch(GetMainMetaTagsAction());
  }, [dispatch]);

  useEffect(() => {
    resizeListener();
    window.onresize = resizeListener;

    const loadImages = async () => {
      await Promise.all(asyncImageLoaders);
    };
    loadImages();
  }, []);

  const paddingTop = useMemo(
    () => (smallLogoWindowSizes.has(appSize) ? 100 : 200),
    [appSize]
  );

  const isLockScreenVisible = useMemo(
    () => !(!showLockScreen || pinVerified),
    [showLockScreen, pinVerified]
  );

  const seoMeta = useMemo(() => data || [], [data]);

  const flexWrapperStyle = useMemo(
    () => ({ width: "100vw", height: "100vh", paddingTop: 0 }),
    [paddingTop]
  );

  return (
    <ParallaxProvider>
      <Router>
        <SEO meta={seoMeta} />
        {isLockScreenVisible && <LockScreen />}
        <Flex column style={flexWrapperStyle}>
          <MobileDrawer />
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/emc2" component={Emc2} />
            <Route path="/services" component={Services} />
            <Route path="/pilots" component={Pilots} />
            <Route path="/business-solutions" component={BusinessSolutions} />
            <Route path="/resources" component={Media} />
            <Route path="/portfolio" component={Portfolio} />
            <Route path="/events" component={Events} />
            <Route path="/risk-profiler" component={RiskProfiler} />
            <Route path="/calendly" component={Calendly} />
            <Route path="*" component={NotFound} />
          </Switch>
          <Footer />
          <AppointmentModal />
          <ContactModal />
          <SuccessAlert />
          <SocialMediaBlock />
          <StaticAppointmentButton />
          <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 100,
              right: 0,
            }}
          >
            <TradingViewMarque />
          </div>
        </Flex>
      </Router>
    </ParallaxProvider>
  );
}

const LockScreen = React.memo(() => {
  const [pin, setPin] = useState("");
  const [pinVerified, setPinVerified] = useLocalStorage("PIN_VERIFIED", false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  useEffect(() => {
    if (pin?.length === 4 && pin === Pin) setPinVerified(true);
  }, [pin]);

  const handleChange = useCallback(({ target }) => {
    let strippedValue = target.value.split("  ").join("");
    strippedValue = strippedValue.replace(/\D/g, "");
    setPin(strippedValue);
  }, []);

  const isValid = useMemo(() => {
    if (pin?.length === 4 && pin !== Pin) return false;
    return true;
  }, [pin]);

  return (
    <Flex center column className={style.lockScreen}>
      <h1 style={{ textAlign: "center", marginBottom: 15 }}>Welcome</h1>
      <p style={{ textAlign: "center", marginBottom: 30 }}>
        Enter the pin provided to you by an admin.
      </p>
      <InputMask
        onChange={handleChange}
        value={pin}
        style={{ color: "rgba(0,0,0,0.5)" }}
        formatChars={{
          b: "[0-9]",
        }}
        maskChar={"-"}
        mask="b  b  b  b"
      >
        {(inputProps) => (
          <input
            {...inputProps}
            placeholder="-  -  -  -"
            className={style.inputBox}
            autoFocus
          />
        )}
      </InputMask>
      <p style={{ color: "red", marginTop: 10, opacity: isValid ? 0 : 1 }}>
        Invalid pin, please try again or contact an administrator
      </p>
    </Flex>
  );
});

const MaintenanceScreen = React.memo(() => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <Flex center column className={style.lockScreen}>
      <h1 style={{ textAlign: "center", marginBottom: 15 }}>
        Welcome to eagleharboradvisors.com
      </h1>
      <h3 style={{ textAlign: "center", marginBottom: 30 }}>
        Unfortunately our website is undergoing maintenance. We'll be back soon!
      </h3>
    </Flex>
  );
});

export default App;
